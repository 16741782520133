// Images
import Project01Detail01 from "../../Assets/Images/Project-01-Detail-01.jpg";
import Project01Detail02 from "../../Assets/Images/Project-01-Detail-02.jpg";
import Project01Detail03 from "../../Assets/Images/Project-01-Detail-03.jpg";
// Components
import Loader from "../../Components/Loader/Loader";
import Hero from "../../Parts/Hero/Hero";
import SubHero from "../../Components/SubHero/SubHero";
import MainPortfolio from "../../Parts/Main-Portfolio/Main-Portfolio";
import Gallery from "../../Parts/Gallery/Gallery";
import CallToActionForm from "../../Parts/Call-To-Action-Form/Call-To-Action-Form";
// Hooks
import useTabTitle from "../../Hooks/useTabTitle";
import useCurrentPage from "../../Hooks/useCurrentPage";
// Css
import "./Project-01.css";

function Project01() {
  // title
  useTabTitle("Beautiful 2BHK House");
  // current page
  useCurrentPage();

  return (
    <>
      <Loader />
      <main id="main" className="portfolio-item project-01">
        <Hero
          title="healthy living in mayiladuthurai"
          content="flexible health facilities in mayiladuthurai"
        />
        <SubHero />

        <div className="container">
          {/* main-column */}
          <MainPortfolio
            title="2BHK HOUSE, mayiladuthurai"
            date="december 7 , 2015"
            client="Ahmed Constructions"
            projectType="residential"
            contractor=""
          >
            <p>
              As part of our commitment to quality construction, Ahmed
              Construction successfully completed the construction of a
              beautiful 2BHK house spanning 1000 sq. ft. This project highlights
              our ability to create functional, well-designed living spaces that
              cater to the unique needs of our clients.
            </p>

            <p>
              From conceptualization to completion, every step of this project
              was carefully managed to ensure the highest standards of
              construction. The result is a modern, comfortable home built with
              precision and attention to detail. We’re proud to have been a part
              of this journey and look forward to taking on more projects that
              make a lasting impact.
            </p>
          </MainPortfolio>

          {/* gallery */}
          <Gallery
            images={[
              { src: Project01Detail01, alt: "Project Detail" },
              { src: Project01Detail02, alt: "Project Detail" },
              { src: Project01Detail03, alt: "Project Detail" },
            ]}
          />
        </div>

        {/* call to action  form */}
        {/* <CallToActionForm /> */}
      </main>
    </>
  );
}

export default Project01;
