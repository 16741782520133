// Images
import AhmedTeam01 from "../../Assets/Images/Ahmed-Team-01.jpg";
import AhmedTeam02 from "../../Assets/Images/Ahmed-Team-02.jpg";
import AhmedTeam03 from "../../Assets/Images/Ahmed-Team-03.jpg";
// Components
import HedSection from "../../Components/Hed-Section/Hed-Section";
import SocialMedia from "../../Components/Social-Media/Social-Media";
// Css
import "./Our-Team.css";

// Fake Server
const data = [
  {
    id: 1,
    image: AhmedTeam01,
    alt: "Ahmed Team",
    name: "safeeq ahmed",
    job: "Founder & CEO",
    facebook: "",
    twitter: "",
    instagram: "ahmed_safeeq_",
    content:
      "With a vision rooted in quality and client satisfaction, I bring a blend of engineering expertise and project management to Ahmed Construction. My focus is on transforming ideas into structures that endure and inspire.",
  },

  {
    id: 2,
    image: AhmedTeam02,
    alt: "Ahmed Team",
    name: "prathap kalaivanan",
    job: "UI/UX Designer",
    facebook: "",
    twitter: "",
    instagram: "0wyte",
    content:
      "As a UI/UX Designer, I specialize in crafting intuitive, user-friendly designs that elevate digital experiences, ensuring every interaction is seamless and impactful.",
  },

  // {
  //   id: 3,
  //   image: AhmedTeam03,
  //   alt: "Ahmed Team",
  //   name: "elizabeth berkley",
  //   job: "operations director",
  //   facebook: "",
  //   twitter: "",
  //   instagram: "",
  //   content:
  //     "duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.",
  // },
];

function OurTeam() {
  return (
    <div className="our-team parent-semicircular">
      {/* semicircular */}
      <div className="semicircular"></div>

      <div className="container">
        <HedSection
          title="meet our team"
          content="At Ahmed Construction, our skilled team combines expertise and passion to deliver high-quality solutions and exceed client expectations. Together, we turn vision into reality."
        />

        <div className="parent-items">
          {data.map((item) => {
            const {
              id,
              image,
              alt,
              name,
              job,
              facebook,
              twitter,
              instagram,
              content,
            } = item;

            return (
              <section key={id} className="Person-Card">
                <div className="parent-img">
                  <img src={image} alt={alt} className="card-img" />
                </div>

                <div className="card-content">
                  <h3 className="title-md">{name}</h3>
                  <h3 className="title-md">{job}</h3>

                  <nav className="card-social">
                    {/* <SocialMedia
                      url={`https://m.facebook.com/${facebook}`}
                      className="card-social-icon"
                      tooltip="facebook"
                      icon="icon-facebook"
                    />

                    <SocialMedia
                      url={`https://www.Twitter.com/${twitter}`}
                      className="card-social-icon"
                      tooltip="twitter"
                      icon="icon-twitter"
                    /> */}

                    <SocialMedia
                      url={`https://www.instagram.com/${instagram}`}
                      className="card-social-icon"
                      tooltip="instagram"
                      icon="icon-instagram"
                    />
                  </nav>

                  <p>{content}</p>
                </div>
              </section>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default OurTeam;
