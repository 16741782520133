import { Link } from "react-router-dom";
// Images
import FooterLogo from "../../Assets/Images/Footer-Logo.webp";
// Components
import Tweet from "../../Components/Tweet/Tweet";
import Map from "../../Parts/Map/Map";
import SocialMedia from "../../Components/Social-Media/Social-Media";
import ScrollUp from "../../Components/Scroll-Up/Scroll-Up";
// Css
import "./Footer.css";

// Fake Server
const data = [
  {
    id: 1,
    url: "https://wa.me/+919894085186/",
    tooltip: "whatsapp",
    icon: "icon-whatsapp",
  },

  // {
  //   id: 2,
  //   url: "https://twitter.com/",
  //   tooltip: "twitter",
  //   icon: "icon-twitter",
  // },

  // {
  //   id: 3,
  //   url: "https://youtube.com/",
  //   tooltip: "youtube",
  //   icon: "icon-youtube",
  // },

  {
    id: 4,
    url: "https://www.instagram.com/ahmed_constructions_/",
    tooltip: "instagram",
    icon: "icon-instagram",
  },
];

function Footer() {
  return (
    <footer className="footer">
      <div className="container container-lg parent-items">
        {/* item 01 */}
        <div className="footer-item">
          <Link to="/" className="footer-logo">
            <img src={FooterLogo} alt="footer logo" title="footer logo" />
          </Link>

          <p>
            Building trust with every project. Ahmed Construction is committed
            to quality, innovation, and delivering exceptional results. Your
            trusted partner in construction.
          </p>

          <Link to="/about" className="btn btn-primary">
            learn more
          </Link>
        </div>

        {/* item 02 */}
        {/* <section className="footer-item">
          <h3 className="title-sm">latest tweets</h3>

          <a href="https://twitter.com/0wyte" className="text-link">
            Tweets by Theme_Fusion
          </a>

          <Tweet />
        </section> */}

        {/* item 03 */}
        <section className="footer-item">
          <h3 className="title-sm">contact us today</h3>

          <ul className="list">
            <li className="list-item">
              <i className="list-icon icon-globe"></i>
              <p>
                Office location
                <br />
                143 /11, 1st floor Jinnah complex
                <br />
                Pattamangala St, Mayiladuthurai - 609001
              </p>
            </li>

            {/* <li className="list-item">
              <i className="list-icon icon-home"></i>
              <p>
                residential location
                <br />
                9521 broadsberry avenue
                <br />
                paddington RC7 9ZA
              </p>
            </li> */}

            <li className="list-item">
              <i className="list-icon icon-phone"></i>
              <p>
                <a href="tel:+919894085186" className="text-link">
                  +919894085186
                </a>
              </p>
            </li>

            <li className="list-item">
              <i className="list-icon icon-phone"></i>
              <a
                href="mailto:mailto:erahmedsafeeq@gmail.com"
                className="text-link"
              >
                erahmedsafeeq@gmail.com
              </a>
            </li>

            <li className="list-item">
              <i className="list-icon icon-clock"></i>
              <p>monday - saturday: 9:00 AM - 7:00 PM</p>
            </li>

            {/* <li className="list-item">
              <i className="list-icon icon-clock"></i>
              <p>saturday - sunday: 9:00 AM - 12:00 PM</p>
            </li> */}
          </ul>
        </section>

        {/* item 04 */}
        <section className="footer-item">
          <h3 className="title-sm">our location</h3>

          <Map />
        </section>
      </div>

      {/* footer nav */}
      <nav className="footer-nav">
        <div className="container container-lg">
          <p>
            &copy;copyright 2024 | Ahmed Constructions by{" "}
            <a href="https://wyte.in/" className="text-link">
              WYTE TECH
            </a>{" "}
            | all rights reserved
          </p>

          <div className="social-media">
            {data.map((item) => {
              const { id, url, tooltip, icon } = item;

              return (
                <div key={id}>
                  <SocialMedia
                    url={url}
                    className="social-media-icon"
                    tooltip={tooltip}
                    icon={icon}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </nav>

      {/* scroll up page */}
      <ScrollUp />
    </footer>
  );
}

export default Footer;
